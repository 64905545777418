import React from 'react'
import { Box, Text } from 'components'
import AnimatedWords from 'screens/Home/components/AnimatedWords'

const PageTitleBanner = ({
  heading,
  subheading,
  wordsToRotate = [],
  rotatingWordsWidth = 275,
}) => {
  return (
    <Box
      sx={{
        padding: [5, 7, 10],
        position: 'relative',
        backgroundColor: 'darkNavy100',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Path />
      </Box>
      <Text
        as="h1"
        textAlign="center"
        sx={{ fontSize: 5, fontWeight: 'display', color: 'white' }}
      >
        {heading}
      </Text>
      {subheading && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: ['column', 'row'],
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 3,
          }}
        >
          <Text
            sx={{
              fontSize: 3,
              color: 'white',
              marginRight: wordsToRotate.length > 0 ? [0, 4] : '0px',
              textAlign: 'center',
            }}
          >
            {subheading}
          </Text>

          {wordsToRotate.length > 0 && (
            <Box sx={{ fontSize: 3 }}>
              <AnimatedWords
                words={wordsToRotate}
                backgroundColor="transparent"
                textColor="white"
                borderColor="white"
                width={rotatingWordsWidth}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default PageTitleBanner

const Path = () => {
  return (
    <svg
      width="1280"
      height="311"
      viewBox="0 0 1280 311"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.25"
        d="M-179.128 -68.0083C-190.94 -61.35 -190.962 -44.3713 -179.168 -37.6821L465.478 327.937C479.696 336.001 497.109 336.022 511.347 327.993L1363.13 -152.369C1374.93 -159.022 1374.96 -175.977 1363.19 -182.679L720.067 -548.898C705.841 -556.999 688.397 -557.036 674.136 -548.997L-179.128 -68.0083Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="156"
          y1="-358.211"
          x2="815.008"
          y2="131.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8093AB" />
          <stop offset="1" stop-color="#BFC7D3" stop-opacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  )
}
