import React from 'react'

import { Section, Container, Box, Heading, Text } from 'components'

import IconTitleDescriptionList from 'screens/Home/components/IconTitleDescriptionList'
import BulletPointsList from 'screens/Home/components/BulletPointsList'

const defaultValues = [
  {
    icon: '/icons/blazing-orange/high-fiving.svg',
    title: 'LÖSUNGSORIENTIERT',
    description:
      'Bei Bambus ist der Name Programm: Die Bambuspflanze ist für Ihre Flexibilität und Stabilität bekannt. Genau diese Eigenschaften zeichnen auch unser Unternehmen aus: von der Abwicklung bis hin zur Vertragsunterzeichnung steht Ihnen Bambus zur Seite.',
  },
  {
    icon: '/icons/blazing-orange/magnifying-glass.svg',
    title: 'TRANSPARENT',
    description:
      'Alle Prozesse und Schritte sind für Sie von Anfang an unkompliziert und nachvollziehbar. Uns ist es wichtig, dass Sie die Abläufe genau verstehen, daher stehen wir Ihnen während und auch nach dem Verkauf für alle Ihre Anliegen stets zur Seite.',
  },
]

const ValuesAndSolutions = ({
  title = 'Auf Bambus können Sie bauen',
  subheading = 'Wir verschaffen finanzielle Flexibilität – einfach, sicher & transparent',
  values = defaultValues,
  solutions = [],
  belowListText = '',
  belowSolutionsText = '',
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section spacing="large" sx={{ backgroundColor }}>
      <Container>
        <Section.Header>
          <Section.Header>
            <Heading.H2 textAlign="center" color="darkNavy80">
              {title}
            </Heading.H2>
            {subheading && (
              <Text textAlign="center" fontSize={3} mt={3} color="darkNavy80">
                {subheading}
              </Text>
            )}
          </Section.Header>
        </Section.Header>

        <Section.Body>
          <IconTitleDescriptionList
            items={values}
            belowListText={belowListText}
          />
          <Box sx={{ height: '75px' }} />
          <BulletPointsList
            title="Wir ermöglichen Teilverkauf "
            items={solutions}
            belowListText={belowSolutionsText}
          />
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}></Section.Footer>
      </Container>
    </Section>
  )
}

export default ValuesAndSolutions
