import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'

import Layout from 'components/Layout'
import { Link, Text } from 'components'

import PageSnippets from 'screens/Home/components/PageSnippets'
import Testimonials from 'screens/Home/components/Testimonials'
import Blog from 'screens/Home/components/Blog'
import Map from 'screens/Home/components/Map'
import ContactFullWidth from 'screens/Home/components/ContactFullWidth'
import PageTitleBanner from 'screens/Home/components/PageTitleBanner'
import SimpleTextBanner from 'screens/Home/components/SimpleTextBanner'
import ValuesAndSolutions from 'screens/Home/components/ValuesAndSolutions'

import { useTranslate } from 'context/location'

const Page = () => {
  const translate = useTranslate()

  const {
    featuredArticles: { articles: featuredArticles },
    contactFullWidthImage,
    salesImage,
    bambusHouseImage,
  } = useStaticQuery(
    graphql`
      query {
        featuredArticles: contentfulFeaturedArticlesLayout(
          node_locale: { eq: "de" }
          slug: { eq: "werte" }
        ) {
          articles {
            ...Article
          }
        }
        contactFullWidthImage: file(
          relativePath: { eq: "business-man-1.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        salesImage: file(relativePath: { eq: "people-at-desk.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bambusHouseImage: file(relativePath: { eq: "bambus-haus.jpeg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout
      seo={{
        title: 'Immobilie beleihen einfach, sicher & transparent',
        description:
          'Bambus verschafft Immobilienbesitzern finanzielle Flexibilität. Einfach, sicher & transparent. Darauf können Sie bauen.',
      }}
    >
      <PageTitleBanner
        heading="Unsere Werte"
        subheading="einfach, sicher &amp; transparent"
      />
      <ValuesAndSolutions
        belowListText="Wir erleichtern Immobilienbesitzern den Zugang zu ihrem Immobilienvermögen. Für mehr finanzielle Flexibilität & Liquidität für den jeweiligen Bedarf. Gemeinsam finden wir die ideale Finanzierungslösung."
        solutions={[
          'Unsere Antragsstrecke ist einfach und nutzerfreundlich',
          'Kompetente und individuelle Beratung',
          'Von der Abwicklung bis hin zur Vertragsunterzeichnung',
          'Keine Gebühren während der Transaktion',
        ]}
        belowSolutionsText={translate({
          DE: 'Dabei ist es uns besonders wichtig, alle finanziellen Möglichkeiten auszuschöpfen. Auch ermöglichen wir individuelle Lösungen wie z.B. den Teilverkauf oder die Leibrente.',
          AT: 'Dabei ist es uns besonders wichtig, alle finanziellen Möglichkeiten auszuschöpfen. Auch ermöglichen wir individuelle Lösungen.',
          _: 'Dabei ist es uns besonders wichtig, alle finanziellen Möglichkeiten auszuschöpfen. Auch ermöglichen wir individuelle Lösungen wie z.B. den Teilverkauf oder die Leibrente.',
        })}
      />
      <ContactFullWidth
        title="Nutzen Sie Bambus für Ihren persönlichen Vorteil"
        subheading="Was darf es für Sie sein?"
        email="beratung@bambus.io"
        phoneNumber={translate({
          DE: '+49 30 837 960 40',
          AT: '+43 1 435 0123',
          _: '+43 1 435 0123',
        })}
        gatsbyFluidImage={contactFullWidthImage.childImageSharp.fluid}
        imagePosition={[
          '25px 0px',
          '100px 0px',
          '200px 0px',
          '240px 0px',
          '500px 40%',
        ]}
        imageTransform={['scale(1.2)', 'scale(1)', 'scale(1)']}
        ctaBlueButtonID="Werte--ContactFullWidth-Blue"
        leadModalTypeBlueButton="teilverkauf_callback"
        ctaPinkButtonID="Werte--ContactFullWidth-Pink"
        leadModalTypePinkButton="teilverkauf"
      />
      <PageSnippets
        title="Sie wollen mehr über Bambus wissen?"
        snippets={[
          {
            id: 1,
            title: 'Top Beratung',
            description:
              'Gemeinsam finden wir Ihre optimale Finanzierungslösung',
            link: '/beratung',
            gatsbyFluidImage: salesImage.childImageSharp.fluid,
            imagePosition: 'center 65%',
          },
          {
            id: 2,
            title: 'Über Bambus',
            description: 'Unsere Mission - unsere Vision - unsere Lösungen',
            link: '/team',
            gatsbyFluidImage: bambusHouseImage.childImageSharp.fluid,
          },
        ]}
      />
      <SimpleTextBanner
        title="Sie möchten mehr Informationen?"
        descriptionText="Wir schicken Ihnen gerne per Mail mehr Information zu. In unserem Infoblatt finden Sie eine genau Beschreibung des Prozesses bei Bambus."
        hideCTA
      />
      <Blog
        title="Tauchen Sie in unseren Ratgeber ein"
        articles={featuredArticles}
        subheadingElement={
          <Text textAlign="center" color="darkNavy80">
            Diese und weitere Artikel zum Thema Teilverkauf und Finanzierungen
            finden Sie in unserem{' '}
            <Link as={RouterLink} to="/ratgeber">
              Ratgeber
            </Link>
          </Text>
        }
      />
      <Testimonials showCTA ctaButtonID="Werte--Testimonials" />
      <Map />
    </Layout>
  )
}
export default Page
